module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ビジネスパーソンのためのクリエイティブ入門 原野守弘","short_name":"クリエイティブ入門 原野守弘","description":"この本は一般的なビジネスパーソンのための「クリエイティブ入門」です。ビジネスパーソンとクリエイターの間にある「断絶」の正体を解き明かし、より多くの人がクリエイティブな発想を持って働けるようになることを目的としています。","lang":"ja","icon":"src/images/favicon.png","start_url":"/","background_color":"#D1E2D3","theme_color":"#fff","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48365577538c445bf160eef5563d69a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
